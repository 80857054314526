import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link, Script } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

import '../assets/scss/main.scss'
import Seo from './Seo'

const Layout = ({ children, location }) => {
  let content

  if (location && location.pathname === '/') {
    content = <div>{children}</div>
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>{children}</div>
      </div>
    )
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            {/* <Seo /> */}
            <Script
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Wyburzanie chojna' },
                { name: 'keywords', content: 'Wyburzanie, chojna, kruszenie beteonu, kruszenie' },
                { name: 'title', content: 'Wyburzanie Chojna' },
              ]}
            />
            {content}
            <CookieConsent
              enableDeclineButton
              flipButtons
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline"
              cookieName="gatsby-gdpr-google-analytics"
              style={{
                background: 'linear-gradient(to right, transparent, #171717)',
                textShadow: '2px 2px black',
              }}
              buttonStyle={{
                background: 'radial-gradient(circle at top right, #222, transparent)',
                color: 'white',
                fontWeight: 'bolder',
                borderRadius: '3px',
                border: '1px black',
                textShadow: '2px 2px black',
              }}
            >
              Strona używa plików cookie, aby zapewnić lepszą obsługę.
            </CookieConsent>
          </>
        )}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
